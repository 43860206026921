
import { IUser } from "@/core/data/user";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import UserService from "@/core/services/UserService";
import { Modal } from "bootstrap";
import { defineComponent, onMounted, ref } from "vue";
import UserRoleService from "@/core/services/UserRoleService";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2";
export default defineComponent({
  components: {
    Datatable,
  },
  props: {
    role: String,
  },
  emit: ["onSaveBtnClick"],
  setup(props, { emit }) {
    let thisModalObj;
    const roleName = ref("");
    const search = ref<string>("");
    var tableData = ref<Array<IUser>>([]);
    var totalData = ref(0);
    var currentPage = ref(1);
    var rowsPerPage = ref(10);
    const addUserRoleModalRef = ref<null | HTMLElement>(null);

    const tableHeader = ref([
      {
        name: "",
        key: "profilePic",
      },
      {
        name: "Name",
        key: "fullName",
        sortable: false,
      },
      {
        name: "Email",
        key: "email",
        sortable: false,
      },
      {
        name: "",
        key: "actions",
      },
    ]);

    const initUserData = () => {
      var searchUserFilter = {
        page: currentPage.value,
        text_search: search.value,
        role_except: props.role,
      };
      UserService.getAllUsers(searchUserFilter).then((response) => {
        totalData.value = response.data.total;
        tableData.value.splice(
          0,
          tableData.value.length,
          ...response.data.data
        );
      });
    };

    const searchItems = () => {
      initUserData();
    };

    const assignUserRole = (user) => {
      var assignRoleRequest = {
        id: user.id,
        roleName: [props.role],
      };
      UserRoleService.addUserRole(assignRoleRequest)
        .then(() => {
          emit("onSaveBtnClick");
          hideModal(addUserRoleModalRef.value);
          Swal.fire({
            text: "บันทึกสำเร็จ",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "บันทึกไม่สำเร็จ",
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    };

    onMounted(() => {
      switch (props.role) {
        case "ROLE_TEACHER":
          roleName.value = "Teacher";
          break;
      }
      thisModalObj = new Modal(addUserRoleModalRef.value);
      //   var userModal = document.getElementById("kt_modal_new_user");
      //   userModal!.addEventListener("hidden.bs.modal", function () {
      //     resetModal();
      //   });
    });

    const onPageChange = (pageNumber) => {
      currentPage.value = pageNumber;
      initUserData();
    };

    const showModal = () => {
      thisModalObj.show();
      initUserData();
    };

    return {
      addUserRoleModalRef,
      showModal,
      roleName,
      initUserData,
      tableData,
      tableHeader,
      search,
      totalData,
      currentPage,
      onPageChange,
      rowsPerPage,
      assignUserRole,
      searchItems,
    };
  },
});
