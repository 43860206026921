import { AxiosResponse } from "axios";
import { IUserRole } from "../data/userRole";
import ApiService from "./ApiService";

const API_URL = "user-roles";

class UserRoleService {
  public static addUserRole(userRoleRequest): Promise<AxiosResponse> {
    return ApiService.post(API_URL, userRoleRequest);
  }
  public static deleteUserRole(userRoleRequest): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/delete", userRoleRequest);
  }
  public static isTeacher(): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/is-teacher");
  }
}

export default UserRoleService;
